<template>
  <div v-if="loaded">
    <b-row class="mt-1 w-100">
      <b-col cols="12" md="12" lg="12" class="mb-2 mt-2">

        <h4 class="mb-0 ">
          <u> {{ msg('Renter') }}</u>
        </h4>
      </b-col>
      <b-col cols="12" md="6" lg="3" class="mb-2 mt-2">

        <b-form-group :label="msg('Firstname')" label-for="realEstate-branch">
          <b-form-input readonly id="email" v-model="client.firstname"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3" class="mb-2 mt-2">

        <b-form-group :label="msg('Lastname')" label-for="realEstate-branch">
          <b-form-input readonly id="email" v-model="client.lastname"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3" class="mb-2 mt-2">

        <b-form-group :label="msg('Email')" label-for="realEstate-branch">
          <b-form-input readonly id="email" v-model="client.email"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3" class="mb-2 mt-2">
        <b-form-group :label="msg('Appointment')" label-for="realEstate-branch">
          <b-form-input readonly v-model="requestDate"/>
        </b-form-group>
      </b-col>

    </b-row>
    <b-row class="mt-1 w-100">
      <b-col cols="12" md="12" lg="12" class="mb-1 mt-1">

        <h4 class="mb-0 ">
          <u> {{ msg('Agent') }}</u>
        </h4>
      </b-col>
      <b-col cols="12" md="4" lg="4" class="mb-2 mt-2">

        <b-form-group :label="msg('Firstname')" label-for="realEstate-branch">
          <b-form-input readonly id="email" v-model="agent.firstname"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="4" class="mb-2 mt-2">

        <b-form-group :label="msg('Lastname')" label-for="realEstate-branch">
          <b-form-input readonly id="email" v-model="agent.lastname"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="4" class="mb-2 mt-2">

        <b-form-group :label="msg('Email')" label-for="realEstate-branch">
          <b-form-input readonly id="email" v-model="agent.email"/>
        </b-form-group>
      </b-col>


    </b-row>
    <b-row class="mt-1 mb-5 w-100">
      <b-col cols="12" md="12" lg="12" class="mb-2 mt-2">

        <h4 class="mb-0 ">
          <u> {{ msg('Send email confirmation to the agent and client') }}</u>


        </h4>
      </b-col>

      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Input address for the mail event')" label-for="email">
          <b-input-group>
            <b-form-input id="email" v-model="request.address"/>
            <b-input-group-append>
              <b-button @click="sendEmail" variant="outline-primary" v-b-tooltip :title="msg('Send Email')">
                EMAIL
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {date} from "@/global/helper";

export default {
  name: 'realEstateAdmin',
  props: {
    value: {
      type: Object,
      required: true,
    },
    requestId: {
      type: String,
      required: false

    }
  },
  data: () => ({
    loaded: false,
    address: "",
    client: {},
    agent: {},
    request: {},
    requestDate: ""
  }),

  computed: {
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

  },
  created() {
    this.retrieveRequestById()

  },
  methods: {
    ...mapActions('realEstateBooking', ['getRequestById', 'sendConfirmMail']),

    retrieveRequestById() {
      if (this.requestId != null)
        this.getRequestById(this.requestId).then(this.onBookingLoaded, this.onError).finally(() => this.loaded = true)

    },
    onBookingLoaded(ans) {
      this.loading = false;
      this.request = ans["BookingRequest"];
      this.client = ans["Client"];
      this.agent = ans["Agent"];
      if (this.request != null)
        this.requestDate = this.request.date + ' ' + this.request.time
    },
    onError(error) {
      this.error = error;
    },
    sendEmail() {
      let $this = this;
      console.log("sending request")

      this.sendConfirmMail(this.request)

    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
