var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Total floors'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Total floors'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","min":"0","step":"0.5","state":errors.length > 0 ? false:null},on:{"change":_vm.onValueUpdated},model:{value:(_vm.realEstate.totalFloors),callback:function ($$v) {_vm.$set(_vm.realEstate, "totalFloors", $$v)},expression:"realEstate.totalFloors"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Floor'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Total floors'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","min":"0","step":"0.5","state":errors.length > 0 ? false:null},on:{"change":_vm.onValueUpdated},model:{value:(_vm.realEstate.floorNumber),callback:function ($$v) {_vm.$set(_vm.realEstate, "floorNumber", $$v)},expression:"realEstate.floorNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Total space'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Total space'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null},on:{"change":_vm.onValueUpdated},model:{value:(_vm.realEstate.realEstateArea.totalSpace),callback:function ($$v) {_vm.$set(_vm.realEstate.realEstateArea, "totalSpace", $$v)},expression:"realEstate.realEstateArea.totalSpace"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Bedrooms'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Bedrooms'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","min":"0","step":"0.5","type":"number","state":errors.length > 0 ? false:null},on:{"change":_vm.onValueUpdated},model:{value:(_vm.realEstate.realEstateArea.rooms),callback:function ($$v) {_vm.$set(_vm.realEstate.realEstateArea, "rooms", $$v)},expression:"realEstate.realEstateArea.rooms"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Beds'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Beds'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","min":"0","step":"0.5","type":"number","state":errors.length > 0 ? false:null},on:{"change":_vm.onValueUpdated},model:{value:(_vm.realEstate.realEstateArea.beds),callback:function ($$v) {_vm.$set(_vm.realEstate.realEstateArea, "beds", $$v)},expression:"realEstate.realEstateArea.beds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Bathrooms'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Bathrooms'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-name","min":"0","step":"0.5","type":"number","state":errors.length > 0 ? false:null},on:{"change":_vm.onValueUpdated},model:{value:(_vm.realEstate.realEstateArea.bathrooms),callback:function ($$v) {_vm.$set(_vm.realEstate.realEstateArea, "bathrooms", $$v)},expression:"realEstate.realEstateArea.bathrooms"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }