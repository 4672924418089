<template>
  <div v-if="loaded">
    <b-row class="mt-1 w-100">
      <b-col cols="12" md="6" lg="3">

        <b-form-group :label="msg('Nuki device')" label-for="realEstate-branch">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              v-model="realEstate.nukiDeviceId"
              :options="allNukis"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">

        <b-form-group :label="msg('Nuki status')" label-for="realEstate-branch">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="realEstate.nukiStatus"
              :options="allNukiStatus"
              class="w-100"
              label="text"
              :reduce="(branch) => branch.value"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group :label="msg('Email to schlosser')" label-for="email">

          <b-input-group>
            <b-form-input id="email" v-model="emailAddress"/>
            <b-input-group-append>
              <b-button variant="outline-primary" v-b-tooltip :title="msg('Send Email to schlosser')"
                        @click="sendEmail">
                EMAIL
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {date} from "@/global/helper";

export default {
  name: 'realEstateNuki',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    nukiList: [],
    emailAddress: ""
  }),
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    ...mapGetters('nuki', ['nukiStatus']),
    allNukiStatus() {
      return Object.values(this.nukiStatus)
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    allNukis() {
      return this.nukiList
          .filter(lock => lock.propertyId === null || lock.propertyId === this.realEstate.id)
          .map(lock => ({
            id: lock.id,
            name: (lock.name)
          }))
    },
  },
  created() {
    Promise.all([this._loadNukiList().then(ans => {
      this.nukiList = ans;
    })])
        .finally(() => this.loaded = true)

  },
  methods: {
    ...mapActions('nuki', {_loadNukiList: 'getNukiList', _sendMail: 'sendMail'}),
    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.description === html)
        return;
      this.realEstate.description = html;
      this.onValueUpdated({quill, html, text})
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    },
    sendEmail() {
      let $this = this;
      if (this.emailAddress != null) {
        this._sendMail({emailAddress: $this.emailAddress, propertyId: this.realEstate.id})
      }
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
