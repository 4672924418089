<template>
  <div class="mt-2">
    <b-row>

      <b-col cols="12">
        <b-row>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.cableSatTv"><i class="fas fa-tv"></i>
              {{ msg("TV") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.airConditioned"><i class="far fa-snowflake"></i>
              {{ msg("Air conditioned") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.wifi"><i class="fas fa-wifi"></i> {{ msg("WIFI") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.swimmingpool"><i class="fa fa-swimming-pool"></i>
              {{ msg("Swimmingpool") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox  style="white-space: nowrap" inline v-model="furnishEdit.teaKitchen">
              <i class="fas fa-kitchen-set"></i> {{ msg("Kitchen") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox style="white-space: nowrap" inline v-model="furnishEdit.parking">
              <i class="fa fa-parking"></i> {{ msg("Parking") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox style="white-space: nowrap" inline v-model="furnishEdit.washingMashine">
              <i class="fa fa-water"></i> {{ msg("Washing machine") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox
                style="white-space: nowrap"
                inline
                v-model="furnishEdit.noPets"
                @change="updatePetsSelection('noPets')">
              <i class="fa fa-paw" style="color: red"></i> {{ msg("Pets not allowed") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox
                style="white-space: nowrap"
                inline
                v-model="furnishEdit.petsWelcome"
                @change="updatePetsSelection('petsWelcome')">
              <i class="fa fa-paw" style="color: #00c853"></i> {{ msg("Pets allowed") }}
            </b-form-checkbox>
          </b-col>


          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox style="white-space: nowrap" inline v-model="furnishEdit.towels">
              <i class="fa fa-bath"></i> {{ msg("Towels") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox style="white-space: nowrap" inline v-model="furnishEdit.hairdryer">
              <i class="fa fa-wind"></i> {{ msg("Hairdryer") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox style="white-space: nowrap" inline v-model="furnishEdit.bedlinen">
              <i class="fa fa-bed"></i> {{ msg("Bed linen") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox style="white-space: nowrap" inline v-model="furnishEdit.dishes">
              <i class="fa fa-utensils"></i> {{ msg("Dishes and cutlery") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="3" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox style="white-space: nowrap" inline v-model="furnishEdit.iron">
              <i class="fa fa-tshirt"></i> {{ msg("Iron") }}
            </b-form-checkbox>
          </b-col>
        </b-row>


      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mergeDeep} from "@/plugins/custom-prototypes";

export default {
  name: 'realEstateFurniture',
  props: ['value'],
  data: () => ({
    intervalId: null,
    furnish: {
      "bath": {
        "tub": false
      },
      "kitchen": {
        "ebk": false
      },
      "floor": {
        "parquet": false
      },
      "heatingSystem": {
        "distant": false,
        "floor": false
      },
      "beacon": {
        "distant": false
      },
      "elevator": {
        "people": false
      },
      "roofShape": {
        "flat": false
      },
      "construction": {
        "massive": false
      },
      "level": {
        "turnkeywithcellar": false
      },
      "energyType": {
        "lowPower": false
      },
      "gastronomy": {
      },
      "security": {
      },
      "expansionStage": {
      },
      "furnished": {
      },
      "basement": {
      },
      "alignmentbalconyterrace": {
        "south": false
      },
    /*  "parkingType": {
        "underground": false
      },*/
      "cableSatTv": false,
      "wheelchairAccessible": false,
      "seniorFriendly": false,
      "storageRoom": false,
      "bikeRoom": false,
      "washdryroom": false,
      "conservatory": false,
      "dvbt": false,
      "umtsReception": false,
      "sportsFacilities": false,
      "wgSuitable": false,
      "roomsChangeable": false,
      "airConditioned": false,
      "guestWC": false,
      "shutters": false,
      "gardenUse": false,
    },
    furnishEdit: null,
  }),
  watch: {
    value: {
      handler() {
        this.checkAndMerge();
        this.$emit("change")
      },
      immediate: true
    },
    furnishEdit: {
      handler() {
        this.$emit('input', this.furnishEdit)
      },
      deep: true,
    }
  },
  methods: {
    updatePetsSelection(selected) {
      if (selected === 'noPets') {
        this.furnishEdit.petsWelcome = !this.furnishEdit.noPets;
      } else if (selected === 'petsWelcome') {
        this.furnishEdit.noPets = !this.furnishEdit.petsWelcome;
      }
    },
    checkAndMerge() {
      if (this.furnishEdit != null && (this.furnishEdit === this.value || JSON.stringify(this.value) === JSON.stringify(this.furnishEdit)))
        return;
      let mergedValue = mergeDeep(this.value, this.furnish, false)
      this.furnishEdit = {...mergedValue};
      this.$emit('input', this.furnishEdit)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
