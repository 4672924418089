<template>
  <div>
    <b-row class="mt-1">


      <!-- RealEstate Name -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('real estate address')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('real estate address')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.address" :state="errors.length > 0 ? false:null"
                          @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('house number')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('house number')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.house" :state="errors.length > 0 ? false:null"
                          @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Apt')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Apt')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.apt" :state="errors.length > 0 ? false:null"
                          @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Zip')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Zip')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.zip" :state="errors.length > 0 ? false:null"
                          @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('City')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('City')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.city" :state="errors.length > 0 ? false:null"
                          @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Location')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Location')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.location" :state="errors.length > 0 ? false:null"
                          @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('State/Province')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('State')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.state" :state="errors.length > 0 ? false:null"
                          @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Country')" label-for="user-country">
          <v-select
              @option:selected="onValueUpdated"
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="realEstate.country"
              :options="allCountries"
              class="w-100"
              label="name"
              :reduce="(lang) => lang.iso"
              autocomplete="on"
          />
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'realEstateGeo',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.description === html)
        return;
      this.realEstate.description = html;
      this.onValueUpdated({quill, html, text})
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
