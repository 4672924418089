<template>
  <div>
    <b-row class="mt-1">

      <b-col cols="2">
        <b-form-group :label="msg('Total floors')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Total floors')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.totalFloors" type="number" min="0" step="0.5"
                          :state="errors.length > 0 ? false:null" @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group :label="msg('Floor')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Total floors')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.floorNumber"
                          type="number" min="0" step="0.5"
                          :state="errors.length > 0 ? false:null" @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

<!--
      <b-col cols="8">
        <b-form-group :label="msg('Floor text')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Floor text')" rules="required">
                <b-form-input id="realEstate-name"  v-model="realEstate.floor"
                              :state="errors.length > 0 ? false:null" @change="onValueUpdated"/>
              </validation-provider>
        </b-form-group>
      </b-col>
-->


<!--      <b-col cols="4">
        <b-form-group :label="msg('Living space')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Living space')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.livingSpace" type="number" min="0"
                          step="0.01"
                          :state="errors.length > 0 ? false:null" @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group :label="msg('Free space')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Size')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.freeSpace" type="number" min="0"
                          step="0.01"
                          :state="errors.length > 0 ? false:null" @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>-->

      <b-col cols="2">
        <b-form-group :label="msg('Total space')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Total space')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.totalSpace" type="number" min="0"
                          step="0.01"
                          :state="errors.length > 0 ? false:null" @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>


      <b-col cols="2">
        <b-form-group :label="msg('Bedrooms')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Bedrooms')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.rooms" min="0" step="0.5"
                          type="number"
                          :state="errors.length > 0 ? false:null" @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
 <b-col cols="2">
        <b-form-group :label="msg('Beds')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Beds')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.beds" min="0" step="0.5"
                          type="number"
                          :state="errors.length > 0 ? false:null" @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group :label="msg('Bathrooms')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Bathrooms')" rules="required">
            <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.bathrooms" min="0" step="0.5"
                          type="number"
                          :state="errors.length > 0 ? false:null" @change="onValueUpdated"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

<!--

      <b-col cols="3">
        <b-form-group :label="msg('Seperated toilets')" label-for="realEstate-name">
          <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.externalToilets" min="0" step="0.5"
                        type="number"
                        @change="onValueUpdated"/>

        </b-form-group>
      </b-col>


      <b-col cols="3">
        <b-form-group :label="msg('Store rooms')" label-for="realEstate-name">
          <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.storeroomCount" min="0" step="0.5"
                        type="number"
                        @change="onValueUpdated"/>

        </b-form-group>
      </b-col>


      <b-col cols="3">
        <b-form-group :label="msg('Logias')" label-for="realEstate-name">
          <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.logiaCount" min="0" step="0.5"
                        type="number"
                        @change="onValueUpdated"/>

        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('Balconies')" label-for="realEstate-name">
          <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.balconycount" min="0" step="0.5"
                        type="number"
                        @change="onValueUpdated"/>

        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('Terraces')" label-for="realEstate-name">
          <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.terraceCount" min="0" step="0.5"
                        type="number"
                        @change="onValueUpdated"/>

        </b-form-group>
      </b-col>


      <b-col cols="3">
        <b-form-group :label="msg('Terrace/Balcony space (sqm)')" label-for="realEstate-name">
          <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.balconyTerraceSpace" min="0" step="0.01"
                        type="number"
                        @change="onValueUpdated"/>

        </b-form-group>
      </b-col>
-->

    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'realEstateArea',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.description === html)
        return;
      this.realEstate.description = html;
      this.onValueUpdated({quill, html, text})
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
