<template>
  <div>
    <b-row class="mt-1">
      <!-- Branch -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Property type')" label-for="realEstate-branch">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="realEstate.propertyType"
              :options="[{value:'HOUSE',label:msg('House')},{value:'APARTMENT',label:msg('Apartment')},{value:'OFFICE',label:msg('Office')},{value:'UNKNOWN',label:msg('Unknown')}]"
              class="w-100"
              label="label"
              :reduce="(pType) => pType.value"
              :clearable="false"
              @option:selected="onValueUpdated"
          />
        </b-form-group>
      </b-col>

      <!-- RealEstate type -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Usage')" label-for="realEstate-type">
          <v-select
              @option:selected="onValueUpdated"
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="realEstate.usageType"
              :options="[
                  {value:{living:true,business:false,investment:false},label:msg('Living')},
                  {value:{living:false,business:true,investment:false},label:msg('Business')},
                  {value:{living:false,business:false,investment:true},label:msg('Investment')}]"
              label="label"
              :reduce="(status) => status.value"
              class="w-100"
          />
        </b-form-group>
      </b-col>

      <!-- RealEstate type -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Marketing type')" label-for="realEstate-type">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="realEstate.marketingType"
              class="w-100"
              :options="[{value:{purchase:false,rent:true},label:msg('Rent')},
              {value:{purchase:true,rent:false},label:msg('Purchase')}]"
              label="label"
              :reduce="(status) => status.value"
              return-object
          />
        </b-form-group>
      </b-col>


      <!-- Branch -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Branch')" label-for="realEstate-branch">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="realEstate.buid"
              :options="allBranches"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              :clearable="false"
              @option:selected="onValueUpdated"
          />
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">

        <b-form-group :label="msg('Status')" label-for="realEstate-branch">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="realEstate.status"
              :options="allStatuses"
              class="w-100"
              label="text"
              :reduce="(branch) => branch.value"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">

        <b-form-group :label="msg('Estate agents')" label-for="realEstate-branch">
          <v-select
              multiple

              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              v-model="realEstate.userIds"
              :options="allAgents"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Owner ID')" label-for="realEstate">
          <client-picker v-model="realEstate.ownerId"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Build year')" label-for="realEstate-sell-price">
          <b-form-input id="realEstate-sell-price" v-model="realEstate.realEstateState.buildYear"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('State')" label-for="realEstate-sell-price">
          <b-form-input id="realEstate-sell-price" v-model="realEstate.realEstateState.state"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Build type')" label-for="realEstate-sell-price">
          <b-form-input id="realEstate-sell-price" v-model="realEstate.realEstateState.ageType"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <!-- Sell Price -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Available from')" label-for="realEstate-sell-price">
          <b-form-input id="realEstate-sell-price" v-model="realEstate.realEstateManagement.availableFrom"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>


      <!-- Sell Price -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Rent period (months,years etc)')" label-for="realEstate-sell-price">
          <b-form-input id="realEstate-sell-price" v-model="realEstate.realEstateManagement.maxRentPeriod"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <!-- Sell Price -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Max rent time')" label-for="realEstate-sell-price">
          <b-form-input id="realEstate-sell-price" v-model="realEstate.realEstateManagement.maxRentTime"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group :label="msg('Number of people')" label-for="realEstate-name">
          <b-form-input id="realEstate-nampole" v-model="realEstate.peoples" type="number" min="0" max="8" step="1"
                        @change="onValueUpdated"/>

        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Building')" label-for="realEstate-sell-price">
          <b-link  @click="openBuildingTab"> {{building.address}}</b-link>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <div class="mt-2">
          <b-form-group :description="msg('Address visible') ">

            <b-form-checkbox
                v-model="realEstate.realEstateManagement.addressVisible"
                name="check-button" switch
                @change="onValueUpdated">
            </b-form-checkbox>
          </b-form-group>

        </div>

      </b-col>

      <!-- 3d link  -->
      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="msg('3D Tour Link')" label-for="realEstate-sell-price">
          <b-form-input id="realEstate-price" v-model="realEstate.realEstateManagement.tour3dLink"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <!-- RealEstate type -->
      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="msg('Room category')" label-for="realEstate-type">
          <v-select
              @option:selected="onValueUpdated"
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="realEstate.imageCategory"
              :options="[
                  {value:'B2',label:('Basic Zweizimmer')},
                  {value:'D2',label:('Deluxe Zweizimmer')},
                  {value:'B1',label:('Basic Einzimmer')},
                  {value:'D1',label:('Deluxe Einzimmer')},
                  ]"
              label="label"
              :reduce="(status) => status.value"
              class="w-100"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="4">
        <b-form-group :label="msg('Name')" label-for="realEstate-sell-price">
          <b-form-input id="realEstate-price" v-model="realEstate.name"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

<!--
      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Hostaway reservations')" label-for="realEstate-sell-price">
          <b-button :disabled="hostawayLoading" @click="fetchHostawayReservations">
            <b-spinner small v-if="hostawayLoading" class="mr-2"></b-spinner>
            Fetch Hostaway reservations
          </b-button>
        </b-form-group>
      </b-col>
-->


    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ClientPicker from "@/components/components/picker/clientPicker";

export default {
  name: 'realEstateManagement',
  components: {ClientPicker},
  props: {
    value: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      hostawayLoading: false,
    }
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    ...mapGetters('realEstate', ['allStatus']),

    allAgents() {
      return this.getAllUsers
          .filter(us => us.roleId === 18)
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatuses() {
      return Object.values(this.allStatus)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {

    openBuildingTab() {
      window.open(`/building/edit/${this.building.id}`, '_blank');
    },
    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.description === html)
        return;
      this.realEstate.description = html;
      this.onValueUpdated({quill, html, text})
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
