var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"mb-2 mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Title text'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Real estate title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.realEstateTitle},on:{"change":_vm.onEditorChange}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Treasure Rent style text'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Treasure rent style text')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.treasureRent},on:{"change":_vm.onEditorChangeTreasure}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Location text'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Real estate location')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.realEstateLocation},on:{"change":_vm.onEditorChange2}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-size":"xl","label":_vm.msg('Description'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Real estate description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.realEstateDescription},on:{"change":_vm.onEditorChange3}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-size":"xl","label":_vm.msg('Layout')+1,"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Real estate layout 1')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.realEstateLayout1},on:{"change":_vm.onEditorChange4}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-size":"xl","label":_vm.msg('Layout')+2,"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Real estate layout 2')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.realEstateLayout2},on:{"change":_vm.onEditorChange5}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-size":"xl","label":_vm.msg('Layout')+3,"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Real estate layout 3')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.realEstateLayout3},on:{"change":_vm.onEditorChange6}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-size":"xl","label":_vm.msg('Layout')+4,"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Real estate layout 4')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.realEstateLayout4},on:{"change":_vm.onEditorChange7}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-size":"xl","label":_vm.msg('Layout')+5,"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Real estate layout 5')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.realEstateLayout5},on:{"change":_vm.onEditorChange8}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-size":"xl","label":_vm.msg('Layout')+6,"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Real estate layout 6')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"realEstate-description",attrs:{"spellcheck":"false","value":_vm.realEstate.realEstateTexts.realEstateLayout6},on:{"change":_vm.onEditorChange9}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }