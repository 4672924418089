<template>
  <div>
    <b-button class="mb-1" style="float: right" variant="primary" @click="showModal.show = true">{{msg('Add Expense')}}</b-button>
    <b-button v-if="realEstate.realEstateExpenses!=null && realEstate.realEstateExpenses.length>0" class="mr-1"
              style="float: right" variant="success" @click="copyToNextExpenses">{{ msg('copynextmonth')}}
    </b-button>
    <b-button v-if="realEstate.realEstateExpenses!=null && realEstate.realEstateExpenses.length>0" class="mr-1"
              style="float: right" variant="danger" @click="copyToPreviousExpenses">{{ msg('copypreviousmonth')}} </b-button>
    <b-table
        v-if="realEstate.realEstateExpenses!=null"
        ref="product-table"
        striped
        hover
        responsive
        v-bind:items="realEstate.realEstateExpenses"
        v-bind:fields="fields"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mx-1"></b-spinner>
          <strong>{{ msg('Loading') }}...</strong>
        </div>
      </template>

      <template #cell(year)="data">
        <b-badge variant="primary">{{ (data.item.year) }} - {{ getMonthName(data.item.month) }}</b-badge>
      </template>

      <template #cell(rent)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(insurance)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(internet)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(gas)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(electricity)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(cleaning)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(damage)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(management)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(commission)="data">
        {{ convertToEURO(data.value) }}
      </template>

      <template #cell(total)="data">
        <b-badge variant="success">{{
            convertToEURO(
                data.item.rent +
                data.item.insurance +
                data.item.gas +
                data.item.internet +
                data.item.cleaning +
                data.item.management +
                data.item.commission +
                data.item.damage +
                data.item.electricity)
          }}
        </b-badge>
      </template>

      <template #cell(actions)="row">
        <b-button size="sm" @click="editOpen(row.item, row.index, $event.target)" class="btn btn-warning">
          {{ msg('Edit') }}
        </b-button>

        <b-button size="sm" @click="deleteOpen(row.item, row.index, $event.target)" class="btn btn-danger">
          {{ msg('Delete') }}
        </b-button>
      </template>
    </b-table>
    <b-modal :id="deleteModal.id" :title="msg('Confirmation')" @ok="deleteExpense(deleteModal.expenseId)"
             ok-variant="danger" :cancel-title="msg('cancel')" @hide="resetInfoModal">
      <p class="my-4">{{ msg('areyousureyouwanttodeletethisexpenses') }}</p>
    </b-modal>

    <b-modal v-model="showModal.show" title="Add Expense" hide-footer>
      <validation-observer ref="refFormObserver">
        <b-row>
          <b-col cols="12" md="6" lg="6" class="p-1">
            <label>Month:</label>
            <validation-provider #default="{ errors }" :name="msg('Month')" rules="required">
              <b-form-select v-model="expense.month" id="month">
                <option v-for="(month, index) in months" :key="index" :value="month.value">{{ month.label }}</option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="p-1">
            <label>Year:</label>
            <validation-provider #default="{ errors }" :name="msg('Year')" rules="required">
              <b-form-select v-model="expense.year" id="year" :options="years">
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="p-1">
            <label>Rent:</label>
            <b-form-input v-model="expense.rent" id="rent" type="number" step="0.1"/>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="p-1">
            <label>Gas:</label>
            <b-form-input v-model="expense.gas" id="gas" type="number" step="0.1"/>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="p-1">
            <label>Electricity:</label>
            <b-form-input v-model="expense.electricity" id="electricity" type="number" step="0.1"/>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="p-1">
            <label>Insurance:</label>
            <b-form-input v-model="expense.insurance" id="insurance" type="number" step="0.1"/>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="p-1">
            <label>Internet:</label>
            <b-form-input v-model="expense.internet" id="internet" type="number" step="0.1"/>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="p-1">
            <label>Damage:</label>
            <b-form-input v-model="expense.damage" id="damage" type="number" step="0.1"/>
          </b-col>

          <b-col cols="12" md="6" lg="6" class="p-1">
            <label>Cleaning:</label>
            <b-form-input v-model="expense.cleaning" id="cleaning" type="number" step="0.1"/>
          </b-col>
        </b-row>
      </validation-observer>
      <div class="text-center border-top pt-1">
        <b-button variant="outline-dark" class="mx-1" style="width: 120px"
                  @click="resetAndCloseModal">{{ msg("Close") }}
        </b-button>
        <b-button v-if="showModal.expenseId==null" variant="outline-success" style="width: 120px" @click="saveExpense">{{ msg("Save") }}
        </b-button>
        <b-button v-else variant="outline-success" style="width: 120px" @click="updateExpense">{{ msg("Update") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'realEstateExpenses',
  data() {
    return {
      deleteModal: {
        id: 'delete-modal',
        title: '',
        content: ''
      },
      showModal: {
        id: 'show-modal',
        title: '',
        content: '',
        show:false,
        expenseId: null,
      },
      fields: [],
      expense: {
        month: "",
        year: "",
        rent: 0,
        gas: 0,
        electricity: 0,
        insurance: 0,
        internet: 0,
        damage: 0,
        cleaning: 0,
      },
      months: [],
      years: [],
      monthNames: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  created() {

    this.setFields();

  },
  mounted() {
    this.getMonthOptions();
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {

    ...mapActions('realEstate', {
      _addExpenses: 'addExpenses',
      _deleteExpenses: 'deleteExpenses',
      _updateExpenses: 'updateExpenses'
    }),

    copyToPreviousExpenses() {
      this.showModal.show = true
      let lastItem = {...this.realEstate.realEstateExpenses[this.realEstate.realEstateExpenses.length - 1]};
      this.expense = lastItem
      this.expense.month = parseInt(lastItem.month) - 1

    },
    copyToNextExpenses() {
      this.showModal.show = true
      let lastItem = {...this.realEstate.realEstateExpenses[this.realEstate.realEstateExpenses.length - 1]};
      this.expense = lastItem
      this.expense.month = parseInt(lastItem.month) + 1

    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    getMonthName(month) {
      return this.monthNames[month - 1]; // Subtract 1 to match array index
    },
    setFields() {
      let $this = this
      this.fields = [
        {
          key: 'year',
          label: $this.msg('date'),
          sortable: true
        },

        {
          key: 'rent',
          label: $this.msg('Rent'),
          sortable: true
        },

        {
          key: 'internet',
          label: $this.msg('internet'),
          sortable: true
        },

        {
          key: 'gas',
          label: $this.msg('gas'),
          sortable: true
        },

        {
          key: 'electricity',
          label: $this.msg('electricity'),
          sortable: true
        },

        {
          key: 'insurance',
          label: $this.msg('insurance'),
          sortable: true
        },
        {
          key: 'damage',
          label: $this.msg('damage'),
          sortable: true
        },
        {
          key: 'cleaning',
          label: $this.msg('cleaning'),
          sortable: true
        },
        {
          key: 'commission',
          label: $this.msg('commission'),
          sortable: true
        },
        {
          key: 'management',
          label: $this.msg('management'),
          sortable: true
        },
        {
          key: 'total',
          label: $this.msg('Total'),
          sortable: true
        },
        {
          key: 'actions',
          label: $this.msg('Actions'),
          sortable: true
        },
      ]
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.deleteModal.id)
    },
    deleteOpen(item, index, button) {
      console.log("item is: ", item)
      this.deleteModal.title = `Row index: ${index}`
      this.deleteModal.propertyId = this.realEstate.id;
      this.deleteModal.expenseId = item.id;
      this.$root.$emit('bv::show::modal', this.deleteModal.id, button)
    },
    editOpen(item, index, button) {
      console.log("item is: ", item)
      this.showModal.propertyId = this.realEstate.id;
      this.showModal.expenseId = item.id;
      this.expense = {...item}
      this.showModal.show = true
    },
    getMonthOptions() {
      for (let i = 0; i < 12; i++) {
        const monthObj = {
          value: i + 1,
          label: new Date(0, i).toLocaleString('default', {month: 'long'})
        };
        this.months.push(monthObj);

        const startYear = 2020;
        const endYear = 2030;

        for (let year = startYear; year <= endYear; year++) {
          this.years.push(year);
        }
      }
    },
    saveExpense() {

      let $this = this;
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        this._addExpenses({id: $this.realEstate.id, expenses: this.expense}).then(() => {
          $this.resetAndCloseModal();
          $this.$emit('loadRealEstate')

        })
      })
    },
    updateExpense() {

      let $this = this;
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        this._updateExpenses({id: $this.realEstate.id, expenses: this.expense}).then(() => {
          $this.resetAndCloseModal();
          $this.$emit('loadRealEstate')

        })
      })
    },
    deleteExpense(expenseId) {

      let $this = this;

      this._deleteExpenses({id: $this.realEstate.id, expenseId: expenseId}).then(() => {
        $this.$emit('loadRealEstate')

      })

    },

    resetAndCloseModal() {

      this.showModal.show = false;
      this.showModal.expenseId = null;
      this.expense = {
        month: "",
        year: "",
        rent: 0,
        gas: 0,
        electricity: 0,
        insurance: 0,
        internet: 0,
        damage: 0,
        cleaning: 0,
      };


    },
    /* onValueUpdated(args) {
       this.$emit('change', args)
     }*/
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
