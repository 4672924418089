<template>
  <div>
    <b-row v-if="loaded === true">
      <b-col v-bind:cols="meta.fieldType==='title'?12:meta.size" v-for="meta in metas" v-bind:key="meta.position">
        <b-form-group>
          <template v-if="meta.fieldType==='int[]'">
            <b-form-group :label="msg(meta.label)" :label-for="`realEstate-meta-${meta.key}`">
              <b-form-tags
                  v-model="realEstate.metadata[meta.key]"
                  v-bind:placeholder="`Enter ${meta.label} separated by space`"
                  separator=" "
                  :tag-validator="v=>!isNaN(v)"
              />
            </b-form-group>
          </template>
          <template v-if="meta.fieldType==='string[]'">
            <b-form-group :label="msg(meta.label)" :label-for="`realEstate-meta-${meta.key}`">
              <b-form-tags
                  v-model="realEstate.metadata[meta.key]"
                  separator=" "
                  v-bind:placeholder="`Enter ${meta.label} separated by space`"
              />
            </b-form-group>
          </template>
          <template v-if="meta.fieldType==='title'">
            <div class="text-center">
              <h3>{{msg(meta.label)}}</h3>
            </div>
          </template>
          <template v-if="meta.fieldType==='string'">
            <b-form-group :label="msg(meta.label)" :label-for="`realEstate-meta-${meta.key}`">
              <b-form-input :id="`realEstate-meta-${meta.key}`" v-model="realEstate.metadata[meta.key]"/>
            </b-form-group>
          </template>
          <template v-if="meta.fieldType==='number'">
            <b-form-group :label="msg(meta.label)" :label-for="`realEstate-meta-${meta.key}`">
              <b-form-input :id="`realEstate-meta-${meta.key}`" type="number" v-model="realEstate.metadata[meta.key]"/>
            </b-form-group>
          </template>
          <template v-if="meta.fieldType==='color'">
            <b-form-group :label="msg(meta.label)" :label-for="`realEstate-meta-${meta.key}`">
              <v-swatches :id="`realEstate-meta-${meta.key}`" v-model="realEstate.metadata[meta.key]"
                          show-fallback shapes="circles"

                          fallback-input-type="color" popover-x="right" swatches="text-advanced"></v-swatches>
            </b-form-group>
          </template>
          <template v-if="meta.fieldType==='dateTime'">
            <b-form-group :label="msg(meta.label)" :label-for="`realEstate-meta-${meta.key}`">
              <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="realEstate.metadata[meta.key]"
                                    :locale="currentFormattedLang" format="YYYY-MM-DD HH:mm" formatted="ll" no-header auto-close>
                <b-form-input :id="`realEstate-meta-${meta.key}`" v-model="realEstate.metadata[meta.key]"/>
              </VueCtkDateTimePicker>
            </b-form-group>
          </template>
          <template v-if="meta.fieldType==='date'">
            <b-form-group :label="msg(meta.label)" :label-for="`realEstate-meta-${meta.key}`">
              <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="realEstate.metadata[meta.key]" :locale="currentFormattedLang" :only-date="true" format="YYYY-MM-DD" formatted="ll" no-header auto-close>
                <b-form-input :id="`realEstate-meta-${meta.key}`" v-model="realEstate.metadata[meta.key]"/>
              </VueCtkDateTimePicker>
            </b-form-group>
          </template>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-else>
      <div class="w-100 text-center">
        <b-spinner class="mx-1" variant="primary" />
        <strong>{{msg('Loading')}}...</strong>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VSwatches from 'vue-swatches'

export default {
  name: 'realEstateMetaEdit',
  components: { VSwatches },
  data: () => ({
    loaded:false,
    metas: [],
    color: ''
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'realEstate.type': {
      handler() {
        this.onLoad()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    ...mapActions('realEstate', ['getRealEstateMetaBase']),
    onLoad() {
      let $this = this;
      this.loaded = false;
      this.checkRealEstateMeta()
      this.getRealEstateMetaBase({ type: this.realEstate.type })
          .then(this.onMetaLoad).finally(()=>$this.loaded = true)
    },
    checkRealEstateMeta() {
      if (this.realEstate.metadata == null) {
        this.realEstate.metadata = {}
      }
    },
    onMetaLoad(metas) {
      console.log('onMetaLoad', metas)
      this.metas = metas.sort((m1, m2) => m1.position - m2.position)
    }
  }
}
</script>

<style scoped>

</style>
