<template>
  <div>
    <b-row class="mt-1">

      <b-col cols="12" class="mb-2 mt-1">
        <b-form-group :label="msg('Title text')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Real estate title')" >
            <quill-editor

                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.realEstateTitle"
                @change="onEditorChange"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="mb-2 mt-1">
        <b-form-group :label="msg('Treasure Rent style text')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Treasure rent style text')" >
            <quill-editor

                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.treasureRent"
                @change="onEditorChangeTreasure"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="mb-2">
        <b-form-group :label="msg('Location text')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Real estate location')" >
            <quill-editor
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.realEstateLocation"
                @change="onEditorChange2"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label-size="xl" :label="msg('Description')" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Real estate description')" >
            <quill-editor
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.realEstateDescription"
                @change="onEditorChange3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label-size="xl" :label="msg('Layout')+1" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Real estate layout 1')" >
            <quill-editor
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.realEstateLayout1"
                @change="onEditorChange4"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label-size="xl" :label="msg('Layout')+2" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Real estate layout 2')" >
            <quill-editor
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.realEstateLayout2"
                @change="onEditorChange5"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label-size="xl" :label="msg('Layout')+3" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Real estate layout 3')" >
            <quill-editor
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.realEstateLayout3"
                @change="onEditorChange6"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label-size="xl" :label="msg('Layout')+4" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Real estate layout 4')" >
            <quill-editor
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.realEstateLayout4"
                @change="onEditorChange7"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label-size="xl" :label="msg('Layout')+5" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Real estate layout 5')" >
            <quill-editor
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.realEstateLayout5"
                @change="onEditorChange8"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label-size="xl" :label="msg('Layout')+6" label-for="realEstate-name">
          <validation-provider #default="{ errors }" :name="msg('Real estate layout 6')" >
            <quill-editor
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="realEstate.realEstateTexts.realEstateLayout6"
                @change="onEditorChange9"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'realEstateText',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.realEstateTitle === html)
        return;
      this.realEstate.realEstateTexts.realEstateTitle = html;
      this.onValueUpdated({quill, html, text})
    },
    onEditorChange2({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.realEstateLocation === html)
        return;
      this.realEstate.realEstateTexts.realEstateLocation = html;
      this.onValueUpdated({quill, html, text})
    },
    onEditorChange3({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.realEstateDescription === html)
        return;
      this.realEstate.realEstateTexts.realEstateDescription = html;
      this.onValueUpdated({quill, html, text})
    },

    onEditorChange4({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.realEstateLayout1 === html)
        return;
      this.realEstate.realEstateTexts.realEstateLayout1 = html;
      this.onValueUpdated({quill, html, text})
    },

    onEditorChange5({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.realEstateLayout2 === html)
        return;
      this.realEstate.realEstateTexts.realEstateLayout2 = html;
      this.onValueUpdated({quill, html, text})
    },

    onEditorChange6({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.realEstateLayout3 === html)
        return;
      this.realEstate.realEstateTexts.realEstateLayout3 = html;
      this.onValueUpdated({quill, html, text})
    },

    onEditorChange7({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.realEstateLayout4 === html)
        return;
      this.realEstate.realEstateTexts.realEstateLayout4 = html;
      this.onValueUpdated({quill, html, text})
    },

    onEditorChange8({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.realEstateLayout5 === html)
        return;
      this.realEstate.realEstateTexts.realEstateLayout5 = html;
      this.onValueUpdated({quill, html, text})
    },

    onEditorChange9({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.realEstateLayout6 === html)
        return;
      this.realEstate.realEstateTexts.realEstateLayout6 = html;
      this.onValueUpdated({quill, html, text})
    },
    onEditorChangeTreasure({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.realEstateTexts.treasureRent === html)
        return;
      this.realEstate.realEstateTexts.treasureRent = html;
      this.onValueUpdated({quill, html, text})
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
