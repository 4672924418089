<template>
  <div>
    <b-row class="mt-1">
      <b-col cols="1">

        <b-img
            :src="realEstate.contactPerson.imageUrl"
            height="60"
            width="60"
            class="mr-1"
        />
      </b-col>

      <b-col cols="2">
        <b-form-group :label="msg('Id')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" readonly v-model="realEstate.contactPerson.personNum"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group :label="msg('Salutation')" label-for="realEstate-type">
          <v-select
              @option:selected="onValueUpdated"
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="realEstate.contactPerson.salutation"
              :options="[{value:'m',label:msg('Mr')},{value:'w',label:msg('Mrs')}]"
              label="label"
              :reduce="(status) => status.value"
              class="w-100"

          />
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group :label="msg('firstname')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.contactPerson.firstname"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('lastname')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.contactPerson.lastname" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>


      <b-col cols="3">
        <b-form-group :label="msg('email')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.contactPerson.email" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('mobile')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.contactPerson.mobile" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('phone')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.contactPerson.phone" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('Fax')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.contactPerson.fax" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="msg('Address')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.contactPerson.address" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('Zip')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.contactPerson.zip" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group :label="msg('city')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.contactPerson.city" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group :label="msg('Logo url')" label-for="realEstate-buy-">
          <b-form-input id="realEstate-buy-" v-model="realEstate.contactPerson.imageUrl" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>


    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'realEstateContact',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.description === html)
        return;
      this.realEstate.description = html;
      this.onValueUpdated({quill, html, text})
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
